exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-drupal-cocktail-detail-js": () => import("./../../../src/templates/drupal-cocktail-detail.js" /* webpackChunkName: "component---src-templates-drupal-cocktail-detail-js" */),
  "component---src-templates-drupal-collection-js": () => import("./../../../src/templates/drupal-collection.js" /* webpackChunkName: "component---src-templates-drupal-collection-js" */),
  "component---src-templates-drupal-contact-js": () => import("./../../../src/templates/drupal-contact.js" /* webpackChunkName: "component---src-templates-drupal-contact-js" */),
  "component---src-templates-drupal-faqs-js": () => import("./../../../src/templates/drupal-faqs.js" /* webpackChunkName: "component---src-templates-drupal-faqs-js" */),
  "component---src-templates-drupal-nutrition-js": () => import("./../../../src/templates/drupal-nutrition.js" /* webpackChunkName: "component---src-templates-drupal-nutrition-js" */),
  "component---src-templates-drupal-page-js": () => import("./../../../src/templates/drupal-page.js" /* webpackChunkName: "component---src-templates-drupal-page-js" */)
}

